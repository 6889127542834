import { Inject, Injectable } from "@angular/core";
import { IUserService } from "./user-service.interface";
import { IUserProfile } from "@mobile-data-access-interfaces";
import { map, Observable, switchMap } from "rxjs";
import { API_ENDPOINT_RESOLVER, IApiEndpointResolver } from "@mobile-data-access-resolvers";
import { HttpClient } from "@angular/common/http";
import { IApiResult } from "@shared-interfaces";

@Injectable()
export class UserService implements IUserService {

  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) { }

  //#endregion

  //#region Methods

  public getProfileAsync(): Observable<IUserProfile> {
    return this._getUrlAsync("user/profile")
      .pipe(
        switchMap((url) =>
          this._httpClient.get<IApiResult<IUserProfile>>(url)
            .pipe(
              map(({ data }) => data)
            )
        )
      );
  }

  //#endregion Methods

  //#region Internal Methods

  protected _getUrlAsync(suffix: string): Observable<string> {
    return this._endpointResolver.loadEndPointAsync("", "").pipe(
      map((baseUrl) => {
        return `${baseUrl}/${suffix}`;
      })
    );
  }

  //#endregion Internal Methods
}
