import { Inject, Injectable } from "@angular/core";
import { IBookmarkService } from "./bookmark-service.interface";
import { API_ENDPOINT_RESOLVER, IApiEndpointResolver } from "@mobile-data-access-resolvers";
import { HttpClient } from "@angular/common/http";
import { catchError, from, map, mergeMap, Observable, retry } from "rxjs";
import { IApiResult, ISearchResult } from "@shared-interfaces";
import { IBookmark, IBookmarkPreview } from "@mobile-data-access-interfaces";
import { DefaultPagination } from "@shared-models";

@Injectable()
export class BookmarkService implements IBookmarkService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {
  }

  //#endregion

  //#region Methods

  public searchAsync(pagination?: DefaultPagination): Observable<ISearchResult<IBookmark[]>> {
    return this._endpointResolver.loadEndPointAsync("", "").pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/bookmark/search`;

        return this._httpClient
          .post<IApiResult<ISearchResult<IBookmark[]>>>(apiUrl, { pagination })
          .pipe(map(apiUrl => {
            return apiUrl?.data;
          }));
      })
    );
  }

  public searchStatusesAsync(data: Omit<IBookmark, "id">[]): Observable<IBookmark[]> {
    if (!data.length) return from(Promise.resolve([]));
    return this._endpointResolver.loadEndPointAsync("", "").pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/bookmark-status/search`;

        return this._httpClient
          .post<IApiResult<IBookmark[]>>(apiUrl, {
            bookmarks: data
          })
          .pipe(
            map(apiUrl => {
              return apiUrl?.data;
            }),
            retry(3),
            catchError(() => from(Promise.resolve([])))
          );
      })
    );
  }

  public addAsync(data: Omit<IBookmark, "id">): Observable<never> {
    return this._endpointResolver.loadEndPointAsync("", "").pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/bookmark`;

        return this._httpClient
          .post<never>(apiUrl, data);
      })
    );
  }

  public deleteAsync(data: Omit<IBookmark, "id">): Observable<never> {
    return this._endpointResolver.loadEndPointAsync("", "").pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/bookmark`;

        return this._httpClient
          .delete<never>(apiUrl, {
            body: data
          });
      })
    );
  }

  public getArticlePreviewByIdsAsync(items: Omit<IBookmark, 'id'>[]): Observable<IBookmarkPreview[]> {
    if (!items?.length) return from(Promise.resolve([]));
    return this._endpointResolver.loadEndPointAsync("", "")
      .pipe(
        mergeMap(baseUrl => {
          const apiUrl = `${baseUrl}/bookmark/article-preview/search`;

          return this._httpClient.post<IApiResult<IBookmarkPreview[]>>(apiUrl, {
            bookmarks: items
          });
        }),
        map(apiResult => apiResult?.data)
      );
  }

  //#endregion
}
