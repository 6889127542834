import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Inject, Injectable } from "@angular/core";
import { catchError, Observable, of, switchMap } from "rxjs";
import { Store } from "@ngrx/store";
import { IRootState, OnboardingComponentStore, UserSelectors } from "@mobile-data-access-stores";
import { IUserService, USER_SERVICE } from "@mobile-data-access-services";
import { OnboardingBeginSteps, OnboardingStatuses } from "@mobile-data-access-enums";
import { IUserProfile } from "@mobile-data-access-interfaces";
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from "@ui-tool/core";

@Injectable()
export class OnboardingGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(USER_SERVICE)
    protected readonly _userService: IUserService,
    protected readonly _store: Store<IRootState>,
    protected readonly _componentStore: OnboardingComponentStore,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._store.select(UserSelectors.profile)
      .pipe(
        switchMap((profile) => {
          if (!profile) {
            return this._userService.getProfileAsync()
              .pipe(
                switchMap((data) => this.resolveAsync(data))
              );
          }

          return this.resolveAsync(profile);
        }),
        catchError(() => {
          return of(false);
        })
      )
  }

  //#endregion

  //#region Methods

  public resolveAsync(profile: IUserProfile): Observable<boolean> {
    this._componentStore.updateValue('acceptTermCondition', profile.acceptTermCondition);
    this._componentStore.updateValue('data', profile.onboarding);
    if ((profile.onboarding.status === OnboardingStatuses.ONBOARDING || profile.onboarding.status === OnboardingStatuses.ONBOARDED)) {
      this._componentStore.updateValue('key', OnboardingBeginSteps.ENTER_PIN_CODE);
    }
    return of(true);
  }

  //#endregion
}
