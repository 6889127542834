import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IBookmarkState } from "./bookmarks.state";
import { bookmarksStoreKey } from "./bookmarks.reducer";

const selectBookmarks = createFeatureSelector<IBookmarkState>(
  bookmarksStoreKey
);

export class BookmarksSelectors {

  //#region Properties

  public static readonly selectBookmarks = createSelector(
      selectBookmarks,
      (state) => state.bookmarks
  );

  public static readonly selectLoading = createSelector(
      selectBookmarks,
      (state) => state.loadingBookmark
  );

  //#endregion

}
