import { NgModule } from "@angular/core";
import { MedicationDrugCardComponent } from './medication-drug-card.component';
import { CommonModule } from "@angular/common";
import { SkeletonModule } from "../../shared";
import { TranslocoModule } from "@ngneat/transloco";

@NgModule({
  declarations: [
    MedicationDrugCardComponent
  ],
  imports: [
    CommonModule,
    SkeletonModule,
    TranslocoModule
  ],
  exports: [
    MedicationDrugCardComponent
  ]
})
export class MedicationDrugCardModule { }
