import { ComponentStore } from '@ngrx/component-store';
import { AppState } from './app.state';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  delay,
  EMPTY,
  filter,
  forkJoin,
  map,
  mergeMap,
  of,
  pipe,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  zip
} from 'rxjs';
import { appInitialState } from './app.constants';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { IRootState, PaxmanActions } from '../features';
import {
  APP_SETTINGS_SERVICE,
  APPOINTMENT_SERVICE,
  DATE_SERVICE,
  I18N_SERVICE,
  IAppointmentService,
  IAppSettingsService,
  IDateService,
  II18nService,
  IPaxmanService,
  IPlatformService,
  IVersionService,
  PAXMAN_SERVICE,
  PLATFORM_SERVICE,
  VERSION_SERVICE
} from '@mobile-data-access-services';
import { IRpcService, RPC_SERVICE, RpcMessage } from '@message-bus/core';
import { NativeMessage } from '@shared-models';
import { WINDOW } from '@ui-tool/core';
import { NativeMessageSources } from '@shared-enums';
import { LocalForageService } from 'ngx-localforage';
import { Languages, StorageKeys } from '@mobile-data-access-enums';
import { TranslocoService } from '@ngneat/transloco';
import { IVersionUpdatesFeatureVM, IVersionUpdatesVM } from '@mobile-data-access-view-models';
import { DOCUMENT } from '@angular/common';
import { VersionCodeMapping } from '@mobile-data-access-models';

@Injectable()
export class AppComponentStore
  extends ComponentStore<AppState>
  implements OnDestroy {
  //#region Properties

  public readonly continueEvent = new Subject();

  public readonly versionData$ = new BehaviorSubject<IVersionUpdatesVM | null>(null);

  private __messageListener: any;

  private readonly __sendToNative$ = new Subject<RpcMessage<any>>();

  private readonly __destroy$ = new Subject<void>();

  private readonly __path$ = new BehaviorSubject(this._dom.location.pathname);

  //#endregion

  //#region Constructor

  public readonly deleteSplashScreen = this.effect<never>(
    pipe(
      debounceTime(250),
      switchMap(() => {
        return of(void 0);
      }),
      delay(1500),
      tap(() => {
        const htmlSplashScreen =
          this._window.document.querySelector('#splash-screen');
        if (!htmlSplashScreen) {
          return;
        }

        if (htmlSplashScreen.classList.contains('hidden')) {
          return;
        }

        htmlSplashScreen.classList.add('hidden');
      })
    )
  );

  public readonly versionChecking = this.effect<never>(
    pipe(
      switchMap(() =>
        this._appSettingsService.loadSettingsAsync(false).pipe(
          switchMap((settings) => {
            if (!settings.updatedFeatureDialogDebug) {
              return of([]);
            }

            return this._localForage.keys().pipe(
              map((keys) => keys.filter((key) => key.includes(`${StorageKeys.VERSION}_`))),
              switchMap(((keys) =>
                  zip(...[StorageKeys.VERSION, ...keys].map((key) => this._localForage.removeItem(key)))
              ))
            );
          })
        )
      ),
      switchMap(() =>
        this._versionService.getCurrentVersionAsync().pipe(
          switchMap((updates) =>
            this._localForage.getItem(StorageKeys.VERSION).pipe(
              switchMap((version) => {
                const obsAsync = (
                  filter: (
                    features: IVersionUpdatesFeatureVM[]
                  ) => IVersionUpdatesFeatureVM[]
                ) => {
                  const features = filter(updates.features);

                  if (!features.length) {
                    this.versionData$.next(null);
                    return of(null);
                  }

                  const data = {
                    ...updates,
                    features
                  };
                  this.versionData$.next(data);
                  return this.continueEvent.pipe(take(1));
                };

                const pathRouting$ = this.__path$.pipe(
                  switchMap((path) => {
                    const featureKey = Object.keys(VersionCodeMapping.mapping).find(
                      (key) => path.includes(VersionCodeMapping.mapping[key])
                    );

                    if (!featureKey) {
                      return of(null);
                    }

                    return this._localForage
                      .getItem(`${StorageKeys.VERSION}_${featureKey}`)
                      .pipe(
                        filter(
                          (featureVersion) => featureVersion !== updates.version
                        ),
                        switchMap(() =>
                          obsAsync((features) =>
                            features.filter(
                              (feature) => feature.code === featureKey
                            )
                          ).pipe(
                            switchMap(() =>
                              this._localForage.setItem(
                                `${StorageKeys.VERSION}_${featureKey}`,
                                updates.version
                              )
                            ),
                            tap(() => this.versionData$.next(null))
                          )
                        )
                      );
                  })
                );

                if (version !== updates.version) {
                  return obsAsync((features) => features.map((feature) => ({ ...feature, descriptions: [] }))).pipe(
                    switchMap(() =>
                      this._localForage.setItem(
                        StorageKeys.VERSION,
                        updates.version
                      )
                    ),
                    tap(() => this.versionData$.next(null)),
                    switchMap(() => pathRouting$)
                  );
                }

                return pathRouting$;
              })
            )
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  //#endregion

  //#region Life cycle hooks
  //#region EFFECTS Methods
  public readonly paxmanCheckAvailableAsync = this.effect<never>(
    pipe(
      mergeMap(() =>
        this._paxmanService.checkAvailabilityAsync().pipe(
          tap((res) => this._store.dispatch(
            PaxmanActions.saveAvailable({
              data: res.available
            })
          ))
        )
      )
    )
  );

  //#endregion

  //#region Methods

  public constructor(
    @Inject(PLATFORM_SERVICE)
    protected readonly _platformService: IPlatformService,
    @Inject(APPOINTMENT_SERVICE)
    protected readonly _appointmentService: IAppointmentService,
    @Inject(DATE_SERVICE) protected readonly _dateService: IDateService,
    @Inject(RPC_SERVICE) protected readonly _rpcService: IRpcService,
    @Inject(WINDOW) protected readonly _window: Window,
    @Inject(I18N_SERVICE)
    protected readonly _i18nService: II18nService,
    protected readonly _localForage: LocalForageService,
    protected readonly _translateService: TranslocoService,
    @Inject(PAXMAN_SERVICE)
    protected readonly _paxmanService: IPaxmanService,
    protected readonly _store: Store<IRootState>,
    @Inject(VERSION_SERVICE)
    protected readonly _versionService: IVersionService,
    @Inject(APP_SETTINGS_SERVICE)
    protected readonly _appSettingsService: IAppSettingsService,
    @Inject(DOCUMENT)
    protected readonly _dom: Document
  ) {
    super(appInitialState);
  }

  public override ngOnDestroy() {
    super.ngOnDestroy();
    this.__destroy$.next(void 0);

    this._window.removeEventListener('message', this.__messageListener);
    this.__messageListener = null;
  }

  //#endregion

  public initialize(): void {
    // Listen to the window message.
    this._window.removeEventListener('message', this.__messageListener);
    this.__messageListener = (event: MessageEvent) => {
      const data = event.data as NativeMessage<any>;

      if (data.source !== NativeMessageSources.NATIVE) {
        return;
      }

      if (data.success) {
        this._rpcService.sendResponse(
          data.namespace,
          data.method,
          data.id || '',
          data.data
        );
      } else {
        this._rpcService.sendException(
          data.namespace,
          data.method,
          data.id || '',
          data.data
        );
      }
    };
    this._window.addEventListener('message', this.__messageListener, false);

    this.__sendToNative$
      .pipe(
        takeUntil(this.__destroy$),
        mergeMap((message) => {
          const nativeMessage = new NativeMessage(
            NativeMessageSources.PWA,
            message.namespace,
            message.method,
            message.data,
            message.id
          );

          if (message.namespace.endsWith('-request')) {
            const actualNamespace = message.namespace.replace('-request', '');
            const clonedNativeMessage = {
              ...nativeMessage,
              namespace: actualNamespace,
              source: NativeMessageSources.PWA
            };

            return this._platformService.sendAsync(clonedNativeMessage);
          }
          return this._platformService.sendAsync(nativeMessage);
        })
      )
      .subscribe();

    this._rpcService
      .hookMethodsRequestsAsync()
      .pipe(takeUntil(this.__destroy$))
      .subscribe((message) => {
        this.__sendToNative$.next(message);
      });

    forkJoin([
      this._localForage.getItem(StorageKeys.LANGUAGE)])
      .pipe(
        take(1),
        takeUntil(this.__destroy$)
      )
      .subscribe(([language]) => {
        this._translateService.setActiveLang(language || Languages.EN);
        this.deleteSplashScreen();
      });
  }

  public updatePath(): void {
    this.__path$.next(this._dom.location.pathname);
  }

  //#endregion
}
