import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { IMedicationState } from './medication.state';
import { catchError, delay, EMPTY, finalize, pipe, switchMap, tap } from 'rxjs';
import {
  IMedicationService,
  MEDICATION_SERVICE,
} from '@mobile-data-access-services';
import { ISearchResult } from '@shared-interfaces';
import { IMedicationDrug } from '@mobile-data-access-interfaces';
import { addMonths, isBefore, parseISO } from 'date-fns';

@Injectable()
export class MedicationComponentStore extends ComponentStore<IMedicationState> {
  //#region Constructor

  public readonly init = this.effect<never>(
    pipe(
      tap(() =>
        this.patchState({
          loading: true,
        })
      ),
      switchMap(() => {
        return this._medicationService
          .getOwnedAsync({
            page: 1,
            size: 10 * 1000,
          })
          .pipe(
            delay(2000),
            tap((searchResult: ISearchResult<IMedicationDrug[]>) => {
              const sixMonthsAgo = addMonths(new Date(), -6);
              const recentItems: IMedicationDrug[] = [];
              const olderItems: IMedicationDrug[] = [];

              searchResult.items?.forEach((item: IMedicationDrug) => {
                const date = parseISO(item.dispensedDate);
                item.isRecent = !isBefore(date, sixMonthsAgo);
                if (item.isRecent) {
                  recentItems.push(item);
                } else {
                  olderItems.push(item);
                }
              });

              searchResult.items = recentItems.concat(olderItems);

              this.patchState({
                getMedicationResult: searchResult,
                message: 'MEDICATION_PAGE.NO_MEDICATION',
              });
            }),
            catchError(() => {
              this.patchState({
                getMedicationResult: {
                  items: [],
                  totalRecords: 0,
                },
                message: 'MEDICATION_PAGE.ERROR',
              });
              return EMPTY;
            }),
            finalize(() => {
              this.patchState({
                loading: false,
              });
            })
          );
      })
    )
  );

  //#endregion

  //#region Methods

  public constructor(
    @Inject(MEDICATION_SERVICE)
    protected readonly _medicationService: IMedicationService
  ) {
    super({
      getMedicationResult: {
        items: [],
        totalRecords: 0,
      },
      loading: false,
      message: 'MEDICATION_PAGE.NO_MEDICATION',
    });
  }

  //#endregion
}
