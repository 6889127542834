import { ComponentStore } from "@ngrx/component-store";
import { catchError, EMPTY, finalize, map, mergeMap, of, pipe, switchMap, take, tap } from "rxjs";
import { BookmarkActions, BookmarksSelectors, IRootState } from "@mobile-data-access-stores";
import { IBookmarkCard, IComponentInitialization } from "@mobile-data-access-interfaces";
import { Inject, Injectable } from "@angular/core";
import { BOOKMARK_SERVICE, IBookmarkService } from "@mobile-data-access-services";
import { Store } from "@ngrx/store";
import { RefresherCustomEvent } from "@ionic/angular";
import { ArticleCategories } from "@mobile-data-access-enums";

@Injectable()
export class BookmarksStore extends ComponentStore<{ }> {

    //#region Constructor

    public constructor(@Inject(BOOKMARK_SERVICE)
                       protected readonly _bookmarkService: IBookmarkService,
                       protected readonly _store: Store<IRootState>) {
        super();
    }

    //#endregion

    //#region Methods
    public readonly loadBookmarks = this.effect<IComponentInitialization>(
        pipe(
            switchMap(initialization => {
                this._store.dispatch(BookmarkActions.changeLoadingStatus({loading: true}));
                return this._bookmarkService.searchAsync()
                    .pipe(
                        mergeMap(({items}) => {
                            return this._bookmarkService.getArticlePreviewByIdsAsync(items)
                                .pipe(
                                    catchError(() => of([])),
                                    map((articlePreviews) => ({items, articlePreviews}))
                                );
                        }),
                        map(({items, articlePreviews}) => {
                            const bookmarks: IBookmarkCard[] = [];
                            for (const item of items) {
                                const articlePreview = articlePreviews.find(x => x.id.toString() === item.itemId && x.category === item.category);
                                const bookmark: IBookmarkCard = {
                                    id: `${item.itemId}`,
                                    imageUrl: articlePreview?.imageUrl || "",
                                    description: articlePreview?.description || "",
                                    title: articlePreview?.title || "",
                                    savedOn: item.createdTime,
                                    category: item.category
                                };

                                bookmarks.push(bookmark);
                            }
                            return bookmarks;
                        }),
                        tap((data) => {
                            this._store.dispatch(BookmarkActions.changeBookmarks({bookmarks: data}));
                        }),
                        catchError(exception => {
                            this._store.dispatch(BookmarkActions.changeBookmarks({bookmarks: []}));
                            return EMPTY;
                        }),
                        finalize(() => {
                            this._store.dispatch(BookmarkActions.changeLoadingStatus({loading: false}));
                            initialization.done?.();
                        })
                    )
            })
        )
    );

    public readonly deleteBookmark = this.effect<IBookmarkCard>(
      pipe(
        mergeMap((item) =>
          this._bookmarkService.deleteAsync({
            itemId: item.id,
            category: item.category as ArticleCategories
          }).pipe(
            mergeMap(() =>
              this._store.select(BookmarksSelectors.selectBookmarks).pipe(
                take(1),
                tap((bookmarks) => {
                  this._store.dispatch(BookmarkActions.changeBookmarks({bookmarks: bookmarks.filter((bookmark) => bookmark.id !== item.id)}));
                })
              )
            ),
            catchError((err) => EMPTY)
          )
        ),
      )
    )


    public readonly initialize = this.effect<never>(
        pipe(
            tap(() => {
                this.loadBookmarks({});
            })
        )
    )

    public readonly reload = this.effect<RefresherCustomEvent>(
        pipe(
            tap(event => this.loadBookmarks({
                done: () => {
                    event.detail.complete();
                }
            }))
        )
    )

    //#endregion
}
